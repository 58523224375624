import React from 'react';
import PropTypes from 'prop-types';

export default function Service({
  img,
  subtitle,
  title,
  url,
}) {
  if (url) {
    return (
      <a href={url} className="service" style={{ backgroundImage: `url(${img})` }}>
        <div className="service__title">{title}</div>
        <div className="service__subtitle">{subtitle}</div>
      </a>
    );
  }
}

Service.defaultProps = {
  url: null,
};

Service.propTypes = {
  img: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};
