/* eslint-disable react/no-array-index-key */
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import { Formik } from 'formik';
import {
  Button,
  Card,
} from '@kajabi/sage-react';
import uuid from 'react-uuid';
import CheckboxGroup from '../../shared/formik/CheckboxGroup';
import Input from '../../shared/formik/Input';
import RadioGroup from '../../shared/formik/RadioGroup';
import Select from '../../shared/formik/Select';
import Textarea from '../../shared/formik/Textarea';
import VendorApplicationPending from '../pending/VendorApplicationsPending';
import {
  averagePrice,
  templatesForSale,
  typicalClients,
} from '../../../data/vendor_applications/options';
import {
  optionsLanguages,
  optionsLocations,
} from '../../../data/shared/options';
import {
  validationCheckboxMinimumOneSelected,
} from '../../../data/shared/validations';
import { initialValues } from '../../../data/vendor_applications/initialValues';
import { validationSchema } from '../../../data/vendor_applications/validationSchema';

import vendorApplicationPost from '../../../scripts/vendorApplicationPost';
import CustomFormMessage from '../../shared/CustomFormMessage';

export default function Questionnaire() {
  const [categories, setCategories] = useState(null);
  const [vendorApplicationSubmitted, setVendorApplicationSubmitted] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    fetch(`${window.location.origin}/categories.json`)
      .then((response) => response.json())
      .then((data) => setCategories(data));
  }, []);

  let errorMessage = validationCheckboxMinimumOneSelected;
  let isError = false;
  let isTriedSubmit = false;

  const validateCount = (values) => {
    const len = values.services.length;
    if (len === 0 && isTriedSubmit) {
      isError = true;
    } else if (len !== 0 && len > 5) {
      isError = true;
      errorMessage = 'Maximum of 5';
    } else {
      isError = false;
    }
  };

  return (
    <div className="section questionnaire">
      <div className="container container--small" ref={formRef}>
        <Card>
          {vendorApplicationSubmitted && (
            <VendorApplicationPending />
          )}
          {!vendorApplicationSubmitted && (
            <Formik
              initialValues={initialValues}
              onSubmit={(values, { setSubmitting }) => {
                const resolvedValues = { ...values };
                setSubmitting(false);
                validateCount(values);
                const servicesAsIntegers = [];
                values.services.forEach((service) => {
                  categories.forEach((category) => {
                    category.subcategories.forEach((subcategory) => {
                      if (service === subcategory.name) {
                        servicesAsIntegers.push(subcategory.id);
                      }
                    });
                  });
                });
                resolvedValues.services = servicesAsIntegers;
                if (!isError) {
                  vendorApplicationPost(resolvedValues)
                    .then(() => {
                      setVendorApplicationSubmitted(true);
                      formRef.current.scrollIntoView();
                    });
                }
              }}
              validationSchema={validationSchema}
            >
              {({
                errors,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <div className="questionnaire__content">
                  <Input
                    errors={errors.firstName}
                    handleChange={handleChange}
                    idName="firstName"
                    label="First name"
                    placeholder="Enter first name"
                    touched={touched.firstName}
                    value={values.firstName}
                  />
                  <Input
                    errors={errors.lastName}
                    handleChange={handleChange}
                    idName="lastName"
                    label="Last name"
                    placeholder="Enter last name"
                    touched={touched.lastName}
                    value={values.lastName}
                  />
                  <Input
                    errors={errors.email}
                    handleChange={handleChange}
                    idName="email"
                    label="Email"
                    placeholder="Enter email"
                    touched={touched.email}
                    value={values.email}
                  />
                  <Input
                    errors={errors.companyName}
                    handleChange={handleChange}
                    idName="companyName"
                    label="Company name"
                    placeholder="Enter your name or company name"
                    touched={touched.companyName}
                    value={values.companyName}
                  />
                  <Input
                    errors={errors.website}
                    handleChange={handleChange}
                    idName="website"
                    label="Website"
                    placeholder="Website URL"
                    touched={touched.website}
                    value={values.website}
                  />
                  <Textarea
                    errors={errors.pitch}
                    handleChange={handleChange}
                    idName="pitch"
                    label="Tell potential clients about yourself. What makes you unique?"
                    placeholder="About me"
                    touched={touched.pitch}
                    value={values.pitch}
                  />
                  <Select
                    handleChange={handleChange}
                    idName="location"
                    label="Where are you located?"
                    options={optionsLocations}
                    value={values.location}
                  />
                  <div className="questionnaire__services">
                    <div className="questionnare__services-list">
                      <p className="t-sage-heading-5 questionnaire__services__title">
                        What services do you offer your clients? Select your top specialties, 5 max.
                      </p>
                      {categories && categories.map((category) => (
                        <CheckboxGroup
                          className="checkbox-group--services"
                          key={uuid()}
                          name="services"
                          onChange={validateCount(values)}
                          options={category.subcategories.map((subcategory) => subcategory.name)}
                          subtitle={category.name}
                        />
                      ))}
                    </div>
                    {isError && (
                      <CustomFormMessage
                        message={errorMessage}
                        type="danger"
                      />
                    )}
                  </div>
                  <Input
                    errors={errors.otherServices}
                    handleChange={handleChange}
                    idName="otherServices"
                    label="Other"
                    placeholder="Tell us about your service..."
                    touched={touched.otherServices}
                    value={values.otherServices}
                  />
                  <CheckboxGroup
                    handleChange={handleChange}
                    name="languages"
                    options={optionsLanguages}
                    title="What languages do you offer services in?"
                  />
                  <RadioGroup
                    handleChange={handleChange}
                    name="templatesForSale"
                    options={templatesForSale}
                    title="Do you offer templates or themes for sale?"
                  />
                  <Input
                    errors={errors.experience}
                    handleChange={handleChange}
                    idName="experience"
                    inputType="number"
                    label="How many years have you been using Kajabi?"
                    placeholder="Years"
                    touched={touched.experience}
                    value={values.experience}
                  />
                  <CheckboxGroup
                    handleChange={handleChange}
                    name="typicalClients"
                    options={typicalClients}
                    title="What is the profile of your typical client?"
                  />
                  <RadioGroup
                    handleChange={handleChange}
                    name="averagePrice"
                    options={averagePrice}
                    subtitle="Jobs on Kajabi Experts are currently project-based."
                    title="On average, how much do you typically charge per project?"
                  />
                  <Textarea
                    errors={errors.additionalInfo}
                    handleChange={handleChange}
                    idName="additionalInfo"
                    label="Tell us about some specific projects you’ve done for Kajabi customers. Include links if possible, as well as three (3) past clients you wouldn’t mind us contacting to learn more about your work."
                    placeholder="Enter a description..."
                    touched={touched.additionalInfo}
                    value={values.additionalInfo}
                  />
                  <Button
                    disabled={isSubmitting}
                    onClick={() => {
                      isTriedSubmit = true;
                      handleSubmit();
                    }}
                  >
                    Submit
                  </Button>
                </div>
              )}
            </Formik>
          )}
        </Card>
      </div>
    </div>
  );
}
