import React, {
  useState,
} from 'react';
import uuid from 'react-uuid';
import {
  Button,
  Checkbox,
  Icon,
} from '@kajabi/sage-react';
import GraphicVendorTerms from './GraphicVendorTerms';
import userAcceptTerms from '../../scripts/userAcceptTerms';

export default function VendorTerms() {
  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleTermsChange = () => {
    setTermsAccepted(!termsAccepted);
  };

  const handleTermsAccepted = () => {
    userAcceptTerms();
    sessionStorage.setItem(
      'toastData',
      JSON.stringify({
        icon: 'check-circle-filled',
        title: 'Thanks! Welcome aboard!',
      }),
    );
    return false;
  };

  const termsCheckboxLabel = (
    <p>
      {'I have read and agree to the '}
      <a
        href="https://kajabi.com/policies/experts-marketplace-agreement"
        target="_blank"
        rel="noopener noreferrer"
        className="t-sage--color-charcoal-400"
      >
        Experts Marketplace Agreement
      </a>
    </p>
  );

  const termsText = (
    <p>
      {'We’re honored to have you involved with Kajabi’s new marketplace. Please review our updated Marketplace Agreement and email us at '}
      <a href="mailto:experts@kajabi.com">experts@kajabi.com</a>
      {' if you have any questions.'}
    </p>
  );

  return (
    <section className="terms">
      <div className="container">
        <div className="sage-row">
          <div className="sage-col sage-col-7">
            <div className="terms__logo">
              <Icon
                color="primary-300"
                icon="kajabi-filled"
                size="2xl"
              />
            </div>
            <div className="terms__content">
              <h1 className="t-sage-heading-1">Welcome, Expert!</h1>
              {termsText}
              <Checkbox
                id={uuid()}
                label={termsCheckboxLabel}
                name="terms-checkbox"
                checked={termsAccepted}
                onChange={handleTermsChange}
              />
            </div>
            <Button
              disabled={!termsAccepted}
              onClick={handleTermsAccepted}
            >
              Continue
            </Button>
          </div>
          <div className="sage-col sage-col-5">
            <div className="terms__image">
              <GraphicVendorTerms />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
