import React from 'react';

export default function Logo() {
  return (
    <div className="logo">
      <svg className="logo__icon" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="31.3125" height="32" rx="6" fill="#0072EF" />
        <path fillRule="evenodd" clipRule="evenodd" d="M8 8V24L23.3121 8H8ZM17.1877 17.6001L11.0624 24H23.3124L17.1877 17.6001Z" fill="white" />
      </svg>
      <div className="logo__text">
        Kajabi
        <span>Experts</span>
      </div>
    </div>
  );
}
