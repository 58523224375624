import React from 'react';
import {
  Button,
} from '@kajabi/sage-react';
import WistiaPlayer from '../shared/WistiaPlayer';
import userOnboarding from '../../scripts/userOnboarding';

export default function Onboarding() {
  return (
    <section className="video">
      <div className="container video__container">
        <div
          className="video__wistia-container"
          id="wistia-player-container-1"
        />
        <p className="t-sage-heading-4 video__description">Welcome, to the first stop in the Experts Marketplace! Watch the video above for a guided tour of the platform and discover how to effortlessly connect with and hire the perfect expert for all of your business needs.</p>
        <div className="video__cta">
          <Button
            size="lg"
            onClick={userOnboarding}
          >
            Create Your First Project
          </Button>
        </div>
        <WistiaPlayer
          videoId="8m3bogr5c0"
          wrapper="wistia-player-container-1"
        />
      </div>
    </section>
  );
}
