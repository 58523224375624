import React, {
  useContext,
} from 'react';
import {
  useNavigate,
} from 'react-router-dom';
import {
  Button,
} from '@kajabi/sage-react';
import ConfigContext from '../../../contexts/ConfigContext';

export default function ActionButtonPrimary() {
  const navigate = useNavigate();
  const {
    authenticityToken,
    loginPath,
    user,
  } = useContext(ConfigContext);
  const buttonText = 'Start a project';

  if (!user) {
    return (
      <form action={loginPath} method="post">
        <Button
          type="submit"
        >
          {buttonText}
        </Button>
        <input
          name="authenticity_token"
          type="hidden"
          value={authenticityToken}
        />
      </form>
    );
  }

  return (
    <Button
      onClick={() => {
        if (user.type === 'user') navigate('/projects/new');
        else window.location.replace(`${window.location.origin}/projects/browse`);
      }}
    >
      {buttonText}
    </Button>
  );
}
