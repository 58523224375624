import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import LinesEllipsis from 'react-lines-ellipsis';
import UIIconStar from '../../../../shared/UIIconStar';
import dateToText from '../../../../../scripts/dateToText';

export default function ReviewsItem({
  ratingObject,
}) {
  const {
    title,
    content,
    rating,
    client,
    createdAt,
  } = ratingObject;

  const prettyDate = dateToText({
    rawDateObject: createdAt,
    prettyDateFull: true,
  });

  const [useEllipses, setUseEllipses] = useState(true);

  const stars = [];
  for (let i = 0; i < rating; i += 1) {
    stars.push(
      <UIIconStar
        key={uuid()}
        size="12"
      />,
    );
  }
  for (let i = 0; i < 5 - rating; i += 1) {
    stars.push(
      <UIIconStar
        color="#D3D5D9"
        key={uuid()}
        size="12"
      />,
    );
  }

  return (
    <div className="reviews-item">
      <div className="reviews-item__info">
        <p className="t-sage-body-small reviews-item__reviewer-name">
          {client}
        </p>
        <p className="t-sage-body-xsmall reviews-item__date">
          {prettyDate}
        </p>
      </div>
      <div className="reviews-item__content">
        <div className="reviews-item__stars">
          {stars}
        </div>
        <p className="t-sage-heading-6 reviews-item__title">{title}</p>
        <div className="t-sage-body-small reviews-item__review">
          {content && useEllipses
            ? (
              <div
                onClick={() => setUseEllipses(false)}
                role="presentation"
              >
                <LinesEllipsis
                  text={content}
                  maxLine="3"
                  ellipsis={<p className="t-sage-body-small reviews-item__ellipses">Read more</p>}
                  trimRight
                  basedOn="words"
                />
              </div>
            )
            : content}
        </div>
      </div>
    </div>
  );
}

ReviewsItem.propTypes = {
  ratingObject: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    rating: PropTypes.number.isRequired,
    client: PropTypes.string.isRequired,
    createdAt: PropTypes.instanceOf(Date).isRequired,
  }).isRequired,
};
