import React from 'react';
import {
  Button,
} from '@kajabi/sage-react';

export default function FAQCallToAction() {
  return (
    <div className="container container--large">
      <div className="faq-cta">
        <img
          alt="Support Team"
          className="faq-cta__image"
          src="images/support_team.png"
        />
        <div className="faq-cta__text">
          <p className="t-sage-heading-2 faq-cta__title">Still have questions?</p>
          <p className="faq-cta__description">Feel free to reach out to us at experts@kajabi.com</p>
        </div>
        <Button
          onClick={() => window.location.replace('mailto:experts@kajabi.com')}
        >
          Get in Touch
        </Button>
      </div>
    </div>
  );
}
